import { api as config } from '@api/utils/api-request';

let API_HOST = '';

switch (process.env.ENV) {
  case 'production':
    API_HOST = 'https://smbcover.ynet.co.il';
    break;

  case 'development':
    API_HOST = `https://${process.env.PROJECT_ID}.firebaseapp.com`;
    break;

  case 'local':
  default:
    API_HOST = `http://localhost:${process.env.API_PORT}`;
    break;
}

export { API_HOST };

export const api = config(API_HOST);
