import React from 'react';

import css from './Footer.module.scss';
import { texts } from 'src/constants/texts';

interface Props {
  storyTitle?: string;
}

export const Footer = (props: Props) => {
  return <footer className={css.footer}>{texts.footer.title}</footer>;
};
