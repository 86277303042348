import React from 'react';

import { texts } from 'src/constants/texts';

import { Partners } from 'src/components/common/Partners';
import ViewLayout from 'src/components/common/ViewLayout';
import { Button } from 'src/components/common/Button';
import { Title } from 'src/components/common/Title';
import { analytics } from 'src/utils/analytics';

import { useAppSelector } from 'src/hooks/store';
import { useResponsive } from 'src/hooks/responsive';
import { selectConfig } from 'src/store/config/selectors';

import css from './Result.module.scss';

const RESULT_IMAGE_ID = 'RESULT_IMAGE';

export const Result = () => {
  const config = useAppSelector(selectConfig);
  const [isMobile] = useResponsive('MOBILE');

  const onDownloadBtnClick = () => {
    const a = document.getElementById(RESULT_IMAGE_ID);
    a?.click();

    analytics.gtag.event({
      event: 'content_click',
      click_text: texts.result.finalStep.btnText,
    });
  };

  const onSampleBtnClick = () => {
    analytics.gtag.event({
      event: 'content_click',
      click_text: texts.result.register.btnText,
    });

    window.open('https://p.ynet.co.il/Asakim-ktanim-15', '_blank');
  };

  React.useEffect(() => {
    analytics.gtag.event({
      event: 'virtualPageview',
      virtualPageURL: 'https://smbcover.ynet.co.il/thank_you',
      virtualPageTitle: 'דף תודה',
    });
  }, []);

  const renderRight = () => {
    return (
      <>
        {!isMobile && <Partners />}
        {!isMobile && <Title />}
        <h2 className={css.subtitle} dangerouslySetInnerHTML={{ __html: texts.result.subtitle }}></h2>
        <div className={css.content}>
          <ul className={css.steps}>
            {texts.result.shareSteps.map((text, i) => {
              return <li key={i} dangerouslySetInnerHTML={{ __html: text }}></li>;
            })}
          </ul>
          <div className={css.tags}>
            {texts.result.tags.map((text, i) => {
              return (
                <div key={i} className={css.tag}>
                  {text}
                </div>
              );
            })}
          </div>
          <div className={css.finalStep}>
            <div className={css.text} dangerouslySetInnerHTML={{ __html: texts.result.finalStep.text }}></div>
            <Button onClick={onDownloadBtnClick} className={css.button} size="medium">
              <span>{texts.result.finalStep.btnText}</span>
            </Button>
          </div>
          <div className={css.register}>
            <div className={css.text} dangerouslySetInnerHTML={{ __html: texts.result.register.text }}></div>
            <Button className={css.button} size="small" variant="secondary" onClick={onSampleBtnClick} border="solid">
              <span>{texts.result.register.btnText}</span>
            </Button>
          </div>
        </div>

        {config.resultImageUrl && (
          <div className={css.hiddenImage}>
            <a id={RESULT_IMAGE_ID} href={config.resultImageUrl} download="result-image.jpeg" />
          </div>
        )}
      </>
    );
  };

  const renderLeft = () => {
    return config.resultImageUrl ? (
      <img className={css.resultImage} src={config.resultImageUrl} alt="Result image" />
    ) : (
      <p>Something went wrong</p>
    );
  };

  return <ViewLayout renderRightPart={renderRight} renderLeftPart={renderLeft} />;
};

export default Result;
