export const analytics = {
  gtag: {
    event: (params: { event: 'virtualPageview' | 'content_click' } & Record<string, string>) => {
      // @ts-expect-error gtag is added in html.head:
      const { dataLayer } = window;

      if (process.env.ENV === 'local') {
        console.log('gtag:local', params);
        return;
      }

      if (dataLayer) {
        dataLayer.push(params);
      } else {
        console.log('%cdataLayer is undefined', 'color: red');
      }
    },
  },
};
