export const texts = {
  footer: {
    title: ' תנאי שימוש | מדיניות פרטיות ',
  },
  general: {
    seo: {
      title: 'מחולל השערים של ידיעות אחרונות ובנק הפועלים',
      description:
        'שער התקווה של העסק שלי. אתם מוזמנים להכין את השער של העסק שלכם – ולהצטרף לתחרות עסקים קטנים 15 של ידיעות אחרונות ובנק הפועלים',
    },
    og: {
      title: 'מחולל השערים של ידיעות אחרונות ובנק הפועלים',
      description:
        'שער התקווה של העסק שלי. אתם מוזמנים להכין את השער של העסק שלכם – ולהצטרף לתחרות עסקים קטנים 15 של ידיעות אחרונות ובנק הפועלים',
    },
    title: 'שער התקווה לעסקים',
    errors: {
      fileInput: {
        size: 'הקובץ לא צריך להיות גדול מ-2Mb',
      },
    },
  },
  intro: {
    subtitle: 'רוצים לדעת איך תראו על השער של העיתון הגדול במדינה?',
    topBlock: {
      title: 'ברוכים הבאים למחולל השערים של פרויקט עסקים קטנים 15.',
      text: 'זאת ההזדמנות שלכם לקדם את העסק שלכם, לתת לו מקום של כבוד בשער הראשי של עיתון ידיעות אחרונות, ולחולל תקווה לצמיחה והצלחה',
    },
    bottomBlock: {
      title: 'אז מה צריך לעשות?',
      text1: 'מעצבים את השער שלכם',
      text2: 'שומרים למכשיר/למחשב',
      text3: 'משתפים בחשבון האינסטגרם/הפייסבוק שלכם<br/>ומתייגים אותנו',
    },
    checkboxOneLabel:
      'אני מאשר שאעשה שימוש במחולל השערים אך ורק לקידום העסק שלי, עם פרטים נכונים ומדויקים. החומרים שאשלב בבעלותי או שיש לי רישיון להשתמש בהם, השער יופץ רק בחשבונות הסושיאל של העסק שלי בצירוף תיוגים לידיעות אחרונות, ynet ובנק הפועלים בע"מ. ידיעות אחרונות, ynet, ובנק הפועלים רשאים להפיץ השער גם כן. אני אחראי בלעדית לכל שימוש שלא בהתאם לתקנון ואשפה את הגופים הנ"ל במקרה של נזק. מבלי לגרוע מכך הגופים הנ"ל רשאים לפקח על הפעילות ולפסול שערים שיופקו במסגרתה.',
    checkboxTwoLabel:
      'הריני מאשר שקראתי, מסכים ומבין את <a href="https://inspire.storycards.com/ynet/%D7%AA%D7%A7%D7%A0%D7%95%D7%9F%20%D7%AA%D7%97%D7%A8%D7%95%D7%AA%20%D7%A2%D7%A1%D7%A7%D7%99%D7%9D%20%D7%A7%D7%98%D7%A0%D7%99%D7%9D%202024%20%D7%A1%D7%95%D7%A4%D7%99.pdf" target="_blank">התקנון</a> הפעילות ו<a href="https://www.ynet.co.il/article/h1oypwysn" target="_blank">מדיניות הפרטיות</a> ומסכים לקבל פרסום מבנק הפועלים ו/או אתר ynet ו/או ידיעות אחרונות, לפי פרטי הנ"ל, שנמסרו על ידי בהסכמתי, הגם שמסירתם איננה מחויבת על פי דין',
    warningMessage: 'נא לאשר את התקנון ואת תנאי השימוש השונים',
    buttonLabel: 'שנתחיל',
  },
  generator: {
    subtitle: 'מלאו את השדות הבאים',
    info: 'לא יודעים מה לכתוב על העסק שלכם? <span>לרעיונות והשראה</span>',
    fileArea: {
      placeholder: 'תמונה',
      additionalPlaceholder: 'הכניסו תמונה ריבועית שמייצגת אתכם ואת העסק שלכם',
    },
    subtitleArea: {
      placeholder: 'כותרת משנה',
    },
    titleArea: {
      placeholder: 'כותרת ראשית',
    },
    static: {
      title: 'דרכים לשפר ולהצמיח את העסק שלכם',
      subtitle: 'כל מה שאתם רוצים שידעו על העסק שלכם',
    },
    navButtons: {
      prevView: 'רוצים לתקן',
      nextView: 'הכל מעולה',
    },
    inputs: {
      textInputCharacterLimit: 'עד {{size}} תווים',
      title: {
        placeholder:
          'כתבו כותרת ראשית חדה, מושכת ומעניינת, שתשקף את העסק שלכם בצורה הכי טובה. לדוגמא: עלמה המעדנייה שמשגעת את הצפון!',
      },
      subtitle: {
        placeholder:
          'הרחיבו בכותרת משנה מסקרנת: מה המהות של העסק שלכם? מה הזהות שלו? מה ייחודי בו? ומה מי שייכנס פנימה צפוי לגלות?',
      },
      image: {
        label: 'העלו תמונה',
        info: 'jpg,png עד 5MB לכל היותר',
        footNote: '*שימו לב - מתעדכן מיד כאן בעמוד!',
      },
    },
  },
  result: {
    subtitle: 'אתם בשער!!!  <span>רגע - אז מה עכשיו?</span>',
    shareSteps: [
      '1. &nbsp; שמרו את השער למכשיר שלכם/למחשב',
      '2. &nbsp; העלו אותו לחשבון האינסטגרם או הפייסבוק שלכם',
      '3. ותייגו אותנו',
    ],
    finalStep: {
      text: '&nbsp4. מבין השערים שיעוצבו נבחר כמה מהיפים והמעניינים ביותר<br/> ונשתף אותם ברשתות החברתיות שלנו וכחלק מפעילות התוכן',
      btnText: 'לשמירת השער',
    },
    tags: ['poalimbank@', 'ynetgram@', 'yedioth@'],
    register: {
      text: 'זה גם הזמן להזמין אתכם להירשם ולקחת חלק <span>בתחרות עסקים קטנים 15,</span> ואולי לזכות בחבילת פרסום ומענק כספי',
      btnText: 'להצטרפות לתחרות',
    },
  },
} as const;
