import React from 'react';
import classNames from 'classnames';

import css from './Icon.module.scss';

export type IconType = 'left-arrow';

interface Props {
  type: IconType;
  className?: string;
  width?: number;
  height?: number;
  color?: string;
  secondaryColor?: string;
}

const Icon = ({ type, className, width = 38, height = 38, color = '#000', secondaryColor, ...iconProps }: Props) => {
  let icon = null;
  const svgProps = {
    width,
    height,
    viewBox: `0 0 ${width} ${height}`,
    xmlns: 'http://www.w3.org/2000/svg',
  };

  switch (type) {
    case 'left-arrow':
      icon = (
        <svg {...svgProps} viewBox="0 0 24 24" fill="none">
          <path d="m10 16-4-4 4-4" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6 12h12" stroke={color} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" />
        </svg>
      );
      break;

    default:
      break;
  }

  return (
    <i className={classNames(css.icon, className)} {...iconProps}>
      {icon}
    </i>
  );
};

export default Icon;
