import React from 'react';

import { texts } from 'src/constants/texts';

import { ImageConstructor } from 'src/components/pages/Main/Generator/ImageConstructor';
import { Partners } from 'src/components/common/Partners';
import ViewLayout from 'src/components/common/ViewLayout';
import { Button } from 'src/components/common/Button';
import { Title } from 'src/components/common/Title';
import { analytics } from 'src/utils/analytics';
import Icon from 'src/components/common/Icon';

import { useAppDispatch } from 'src/hooks/store';
import { useResponsive } from 'src/hooks/responsive';
import { setView } from 'src/store/config';

import { Checkbox } from './Checkbox';

import css from './Intro.module.scss';

export const Intro = () => {
  const dispatch = useAppDispatch();
  const [isWarningMsgVisible, setIsWarningMsgVisible] = React.useState(false);
  const [isChOneChecked, setIsChOneChecked] = React.useState(false);
  const [isChTwoChecked, setIsChTwoChecked] = React.useState(false);
  const [isMobile] = useResponsive('MOBILE');

  const onBtnClick = () => {
    if (!isChOneChecked || !isChTwoChecked) {
      setIsWarningMsgVisible(true);
    } else {
      dispatch(setView('form'));

      analytics.gtag.event({
        event: 'content_click',
        click_text: texts.intro.buttonLabel,
      });
    }
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;

    if (isWarningMsgVisible) {
      setIsWarningMsgVisible(false);
    }

    if (name === 'ch1') setIsChOneChecked(e.currentTarget.checked);
    if (name === 'ch2') setIsChTwoChecked(e.currentTarget.checked);
  };

  const renderRight = () => {
    return (
      <>
        {!isMobile && <Partners />}
        {!isMobile && <Title className={css.title} />}
        <h2 className={css.subtitle}>{texts.intro.subtitle}</h2>
        <div className={css.content}>
          <div className={css.block}>
            <h5>{texts.intro.topBlock.title}</h5>
            <p>{texts.intro.topBlock.text}</p>
          </div>
          <div className={css.block}>
            <h5>{texts.intro.bottomBlock.title}</h5>
            <p className={css.flex}>
              <span>
                <i />
                {texts.intro.bottomBlock.text1}
              </span>
              <span>
                <i />
                {texts.intro.bottomBlock.text2}
              </span>
              <span dangerouslySetInnerHTML={{ __html: texts.intro.bottomBlock.text3 }} />
            </p>
          </div>

          <div className={css.checkboxes}>
            <Checkbox
              onChange={onCheckboxChange}
              label={texts.intro.checkboxOneLabel}
              name="ch1"
              checked={isChOneChecked}
              isError={isWarningMsgVisible && !isChOneChecked}
            />
            <Checkbox
              className={css.ch2}
              onChange={onCheckboxChange}
              label={texts.intro.checkboxTwoLabel}
              name="ch2"
              isError={isWarningMsgVisible && !isChTwoChecked}
              checked={isChTwoChecked}
            />
          </div>

          {isWarningMsgVisible && <div className={css.errorMsg}>{texts.intro.warningMessage}</div>}
        </div>

        <Button className={css.button} size="large" variant="secondary" onClick={onBtnClick} border="dashed">
          <span>{texts.intro.buttonLabel}</span>
          <Icon color="#D9000D" type="left-arrow" className={css.icon} />
        </Button>
      </>
    );
  };

  const renderLeft = () => <ImageConstructor mode="static" />;

  React.useEffect(() => {
    analytics.gtag.event({
      event: 'virtualPageview',
      virtualPageURL: 'https://smbcover.ynet.co.il/start',
      virtualPageTitle: 'התחלה',
    });
  }, []);

  return <ViewLayout renderRightPart={renderRight} renderLeftPart={renderLeft} />;
};

export default Intro;
