import React, { FC } from 'react';
import cn from 'classnames';

import css from './Button.module.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: React.ReactNode;
  className?: string;
  size?: 'large' | 'medium' | 'small';
  variant?: 'primary' | 'secondary';
  border?: 'solid' | 'dashed' | 'none';
}

export const Button: FC<ButtonProps> = (props) => {
  const { className = '', size = 'medium', children, variant = 'primary', border = 'none', type, ...rest } = props;

  return (
    <button
      className={cn(css.button, css[`size-${size}`], css[`variant-${variant}`], css[`border-${border}`], className)}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
};
