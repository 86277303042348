import React from 'react';
import cn from 'classnames';

import css from './Checkbox.module.scss';

type Props = {
  className?: string;
  label: string;
  name: string;
  checked: boolean;
  isError?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const Checkbox: React.FC<Props> = ({ label, onChange, name, checked, className = '', isError = false }) => {
  return (
    <div className={cn(css.checkbox, isError && css.error, className)}>
      <label>
        <input name={name} type="checkbox" checked={checked} onChange={onChange} />
        <div className={css.checkmark} />
        <div dangerouslySetInnerHTML={{ __html: label }} />
      </label>
    </div>
  );
};
