import React from 'react';
import cn from 'classnames';
import { useField } from 'formik';
import DOMPurify from 'dompurify';

import { texts } from 'src/constants/texts';

import css from './TextArea.module.scss';

type Props = {
  disabled?: boolean;
  name: string;
  charactersLimit: number;
  className?: string;
  placeholder: string;
  rows?: number;
};

const TextArea: React.FC<Props> = ({ className = '', name, rows, disabled = false, charactersLimit, placeholder }) => {
  const [field, meta, helpers] = useField(name);

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = DOMPurify.sanitize(e.target.value);

    if (value.length <= charactersLimit) {
      helpers.setValue(value);
    }
  };

  return (
    <div className={cn(css.textArea, className)}>
      <textarea rows={rows ?? 2} placeholder={placeholder} {...field} onChange={onTextAreaChange} disabled={disabled} />
      <div className={css.info}>
        ({texts.generator.inputs.textInputCharacterLimit.replace('{{size}}', `${charactersLimit}`)})
      </div>
    </div>
  );
};

export default TextArea;
