import cn from 'classnames';
import React from 'react';

import { texts } from 'src/constants/texts';

import css from './Title.module.scss';

interface Props {
  className?: string;
}

export const Title: React.FC<Props> = (props) => {
  const { className = '' } = props;

  return <h1 className={cn(css.title, className)}>{texts.general.title}</h1>;
};
