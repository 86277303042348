import React from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import cn from 'classnames';

import {
  Params as FormValues,
  paramsSchema as generateParamsSchema,
  SUBTITLE_MAX_LENGTH,
  TITLE_MAX_LENGTH,
} from '@api/routes/public/submissions/generate/params';

import { texts } from 'src/constants/texts';

import ViewLayout from 'src/components/common/ViewLayout';
import { Partners } from 'src/components/common/Partners';
import { Button } from 'src/components/common/Button';
import { Loader } from 'src/components/common/Loader';
import { Title } from 'src/components/common/Title';
import Icon from 'src/components/common/Icon';

import { submissions } from 'src/store/api/submissions';
import { analytics } from 'src/utils/analytics';
import { useAppDispatch } from 'src/hooks/store';
import { useResponsive } from 'src/hooks/responsive';
import { setResultImageUrl, setView } from 'src/store/config';

import { ImageConstructor } from './ImageConstructor';
import UploadInput from './UploadInput';
import TextArea from './TextArea';

import pdfUrl from 'src/assets/newspaper-info.pdf';

import css from './Generator.module.scss';

const Generator = () => {
  const dispatch = useAppDispatch();
  const [isMobile] = useResponsive('MOBILE');
  const [generate, generateResultState] = submissions.endpoints.generate.useMutation();
  const infoText = texts.generator.info
    .replace('<span>', `<a href="${pdfUrl}" target="_blank">`)
    .replace('</span>', '</a>');
  const formikContext = useFormik<FormValues>({
    enableReinitialize: true,
    validationSchema: generateParamsSchema,
    initialValues: {
      title: '',
      subtitle: '',
      coverImage: null,
    },
    onSubmit: (values) => {
      analytics.gtag.event({
        event: 'content_click',
        click_text: texts.generator.navButtons.nextView,
      });

      generate({ params: values });
    },
  });

  React.useEffect(() => {
    analytics.gtag.event({
      event: 'virtualPageview',
      virtualPageURL: 'https://smbcover.ynet.co.il/form',
      virtualPageTitle: 'טופס מילוי פרטים',
    });
  }, []);

  React.useEffect(() => {
    if (generateResultState.isSuccess && generateResultState.data.success) {
      dispatch(setResultImageUrl(generateResultState.data.data));
      dispatch(setView('result'));
    } else {
      if (generateResultState.status !== 'uninitialized') console.info('ERROR:', generateResultState);
    }
  }, [generateResultState.isSuccess]);

  const onPrevBtnClick = () => {
    dispatch(setView('intro'));

    analytics.gtag.event({
      event: 'content_click',
      click_text: texts.generator.navButtons.prevView,
    });
  };

  const renderRight = () => {
    return (
      <FormikProvider value={formikContext}>
        <Form>
          {!isMobile && <Partners />}
          {!isMobile && <Title />}
          <div className={css.subtitle}>
            <h3>{texts.generator.subtitle}</h3>
          </div>
          <div className={css.inputs}>
            <TextArea
              disabled={generateResultState.isLoading}
              className={css.textField}
              name="title"
              placeholder={texts.generator.inputs.title.placeholder}
              charactersLimit={TITLE_MAX_LENGTH}
              rows={isMobile ? 3 : 2}
            />
            <TextArea
              disabled={generateResultState.isLoading}
              className={cn(css.textField, css.subtitleField)}
              name="subtitle"
              placeholder={texts.generator.inputs.subtitle.placeholder}
              charactersLimit={SUBTITLE_MAX_LENGTH}
              rows={isMobile ? 3 : 2}
            />

            <UploadInput disabled={generateResultState.isLoading} className={css.image} name="coverImage" />

            <div className={css.info} dangerouslySetInnerHTML={{ __html: infoText }} />
          </div>
          <div className={css.navButtons}>
            <Button
              disabled={generateResultState.isLoading}
              className={css.button}
              variant="secondary"
              border="dashed"
              onClick={onPrevBtnClick}
              size="medium"
            >
              <Icon color="#D9000D" type="left-arrow" className={css.icon} />
              <span> {texts.generator.navButtons.prevView}</span>
            </Button>
            <Button
              disabled={generateResultState.isLoading || !formikContext.isValid || !formikContext.dirty}
              type="submit"
              className={css.button}
              size="medium"
            >
              <span>{texts.generator.navButtons.nextView}</span>
              <Icon color="white" type="left-arrow" className={cn(css.icon, css.next)} />
            </Button>
          </div>
        </Form>
      </FormikProvider>
    );
  };

  return (
    <ViewLayout
      renderLeftPart={() => (
        <>
          <ImageConstructor
            title={formikContext.values.title}
            subtitle={formikContext.values.subtitle}
            image={formikContext.values.coverImage}
            mode="dynamic"
          />
          {generateResultState.isLoading && (
            <div className={css.loaderWrapper}>
              <Loader color="red" className={css.loader} />
            </div>
          )}
        </>
      )}
      renderRightPart={renderRight}
    />
  );
};

export default Generator;
