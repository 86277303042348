import React from 'react';
import cn from 'classnames';

import css from './ViewLayout.module.scss';

type Props = {
  className?: string;
  renderRightPart: () => React.ReactNode;
  renderLeftPart: () => React.ReactNode;
};
const ViewLayout: React.FC<Props> = ({ className = '', renderRightPart, renderLeftPart }) => {
  return (
    <div className={cn(css.viewLayout, className)}>
      <div className={css.right}>{renderRightPart()}</div>
      <div className={css.left}>{renderLeftPart()}</div>
    </div>
  );
};

export default ViewLayout;
