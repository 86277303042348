import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ConfigState = { resultImageUrl: string | null; view: 'intro' | 'form' | 'result' };

const initialState: ConfigState = {
  view: 'intro',
  resultImageUrl: null,
};

export const config = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setView: (state, action: PayloadAction<ConfigState['view']>) => {
      state.view = action.payload;
    },
    setResultImageUrl: (state, action: PayloadAction<ConfigState['resultImageUrl']>) => {
      state.resultImageUrl = action.payload;
    },
  },
});

export const { setView, setResultImageUrl } = config.actions;
export default config.reducer;
