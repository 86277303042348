import React from 'react';
import { useLocation } from 'react-router';

import { DOMAIN_URL } from 'src/constants/other';
import { texts } from 'src/constants/texts';

import { selectConfig } from 'src/store/config/selectors';
import { Footer } from 'src/components/common/Footer';
import { Partners } from 'src/components/common/Partners';
import { Title } from 'src/components/common/Title';
import { useAppSelector } from 'src/hooks/store';
import { useResponsive } from 'src/hooks/responsive';
import Head from 'src/components/common/Head';

import bg from 'src/assets/bg.jpg';

import Generator from './Generator';
import Result from './Result';
import Intro from './Intro';

import css from './Main.module.scss';

const Main = () => {
  const location = useLocation();
  const config = useAppSelector(selectConfig);
  const [isMobile] = useResponsive('MOBILE');

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [config.view]);

  const renderContent = () => {
    switch (config.view) {
      case 'intro':
        return <Intro />;
      case 'form':
        return <Generator />;
      case 'result':
        return <Result />;
      default:
        return null;
    }
  };

  return (
    <>
      <Head
        seo={{ title: texts.general.seo.title, description: texts.general.seo.description }}
        og={{ title: texts.general.og.title, description: texts.general.og.description }}
      >
        <link rel="canonical" href={`${DOMAIN_URL}${location.pathname}${location.search}`}></link>
      </Head>

      <div className={css.main} style={config.view !== 'form' ? { backgroundImage: `url('${bg}')` } : undefined}>
        {isMobile && <Partners />}
        {isMobile && <Title />}
        {renderContent()}
        <Footer />
      </div>
    </>
  );
};

export default Main;
