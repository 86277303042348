import React from 'react';
import cn from 'classnames';

import imageSrc from 'src/assets/empty-with-image-template.png';
import { texts } from 'src/constants/texts';

import { ContentArea } from './ContentArea';

import css from './ImageConstructor.module.scss';

interface Props {
  className?: string;
  mode: 'static' | 'dynamic';
  title?: string;
  subtitle?: string;
  image?: Blob | null;
}

export const ImageConstructor = (props: Props) => {
  const { className = '', title = '', subtitle = '', image = null, mode = 'static' } = props;

  const renderStaticTexts = () => {
    return (
      <>
        <div className={css.staticTitle}>{texts.generator.static.title}</div>
        <div className={css.staticSubtitle}>{texts.generator.static.subtitle}</div>
      </>
    );
  };

  const renderEditableAreas = () => {
    return (
      <>
        <ContentArea
          borderColor="blue"
          backgroundColor="rgba(255,255,255, 0.9)"
          value={image}
          placeholder={texts.generator.fileArea.placeholder}
          additionalPlaceholder={texts.generator.fileArea.additionalPlaceholder}
          placeholderClassName={css.imageFilePlaceholder}
          className={css.imageFileArea}
        />
        <ContentArea
          borderColor="white"
          value={title}
          placeholder={texts.generator.titleArea.placeholder}
          backgroundColor="linear-gradient(341.71deg, #034296 0.65%, #023b86 94.05%)"
          placeholderClassName={css.titleAreaPlaceholder}
          className={css.titleArea}
        />
        <ContentArea
          borderColor="white"
          value={subtitle}
          placeholder={texts.generator.subtitleArea.placeholder}
          backgroundColor="#7E0309"
          placeholderClassName={css.subtitleAreaPlaceholder}
          className={css.subtitleArea}
        />
      </>
    );
  };

  return (
    <div className={cn(css.imageConstructor, className)}>
      <img src={imageSrc} />
      {mode === 'dynamic' && renderEditableAreas()}
      {mode === 'static' && renderStaticTexts()}
    </div>
  );
};
