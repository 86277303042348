import React from 'react';
import cn from 'classnames';

import ynetLogo from 'src/assets/partners/ynet.svg';
import hapLogo from 'src/assets/partners/hap.png';
import poalimLogo from 'src/assets/partners/poalim.png';

import css from './Partners.module.scss';

type Props = {
  className?: string;
};

export const Partners: React.FC<Props> = ({ className = '' }) => {
  return (
    <div className={cn(css.partners, className)}>
      <a className={cn(css.partner, css.poalim)} href="https://www.bankhapoalim.co.il/he" target="_blank">
        <img src={poalimLogo} alt="" />
      </a>

      <a className={cn(css.partner, css.hap)} href="https://www.ynet.co.il/economy/article/sjznsfpar" target="_blank">
        <img src={hapLogo} alt="" />
      </a>

      <a className={cn(css.partner, css.ynet)} href="https://www.ynet.co.il/home/0,7340,L-8,00.html" target="_blank">
        <img src={ynetLogo} alt="" />
      </a>
    </div>
  );
};
