import { generatePath } from 'react-router';

import { API_HOST } from 'src/utils/api';

import {
  ROUTE as GENERATE_ROUTE,
  Result as GenerateResult,
  Params as GenerateParams,
} from '@api/routes/public/submissions/generate/params';
import {
  ROUTE as GET_ROUTE,
  Result as GetResult,
  Params as GetParams,
} from '@api/routes/public/submissions/get/params';
import { renameFile } from 'src/utils/rename-file';

import { ApiResponse } from '@api/types';
import api from '.';

export const submissions = api.injectEndpoints({
  endpoints: (builder) => ({
    generate: builder.mutation<ApiResponse<GenerateResult>, { params: GenerateParams }>({
      queryFn: async ({ params }) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();
          const request = new XMLHttpRequest();

          formData.append('title', params.title);
          formData.append('subtitle', params.subtitle);
          if (params.coverImage) formData.append('coverImage', renameFile(params.coverImage as File, 'coverImage'));

          request.onload = () => {
            try {
              const response = JSON.parse(request.response);
              resolve({
                data: response as ApiResponse<GenerateResult>,
              });
            } catch (e) {
              reject(e);
            }
          };
          request.open('POST', `${API_HOST}${GENERATE_ROUTE}`);
          request.withCredentials = true;
          request.send(formData);
        });
      },
    }),

    getSubmission: builder.query<ApiResponse<GetResult>, GetParams>({
      query: (params) => {
        console.info('getSubmission', generatePath(GET_ROUTE, { id: params.id }));
        return {
          method: 'GET',
          url: generatePath(GET_ROUTE, { id: params.id }),
        };
      },
    }),
  }),
  overrideExisting: false,
});

export { ApiResponse };
