import React from 'react';
import cn from 'classnames';

import css from './Loader.module.scss';

interface Props {
  className?: string;
  color?: 'white' | 'red';
}

export const Loader = ({ className = '', color = 'white' }: Props) => {
  return (
    <div className={cn(css.ldsRing, css[color], className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
