import React from 'react';
import { useParams } from 'react-router';

import { TEMPLATE_WRAPPER_CLASSNAME } from '@api/constants/other';

import { submissions as submissionsApi } from 'src/store/api/submissions';

import newspaperTemplate from 'src/assets/empty-template.png';

import css from './ImageTemplate.module.scss';

const ImageTemplate = () => {
  const { submissionId } = useParams();
  const { data } = submissionsApi.endpoints.getSubmission.useQuery(
    { id: submissionId || '' },
    { skip: !Boolean(submissionId) },
  );
  const submission = data?.success ? data.data : undefined;

  return (
    <div className={css.imageTemplate}>
      <div className={TEMPLATE_WRAPPER_CLASSNAME}>
        <img className={css.tImage} src={newspaperTemplate} alt="" />
        <div className={css.title}>{submission?.title}</div>
        <div className={css.subtitle}>
          <span>{submission?.subtitle}</span>
        </div>

        <div
          className={css.coverImage}
          style={submission?.image ? { backgroundImage: `url('${submission?.image}')` } : undefined}
        />
      </div>
    </div>
  );
};

export default ImageTemplate;
