import React from 'react';
import { Route, createRoutesFromElements } from 'react-router-dom';

import { IMAGE_TEMPLATE, ROOT } from 'src/constants/routes';

import PageLayout from 'src/components/common/PageLayout';
import Main from 'src/components/pages/Main';
import ImageTemplate from 'src/components/pages/ImageTemplate';

export const Routes = (
  <>
    <Route path={ROOT} element={<PageLayout />}>
      <Route path={ROOT} element={<Main />} />
      <Route path={IMAGE_TEMPLATE} element={<ImageTemplate />} />
    </Route>
  </>
);

export const routes = createRoutesFromElements(Routes);
export default routes;
