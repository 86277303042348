import React from 'react';
import cn from 'classnames';

import css from './ContentArea.module.scss';

interface Props {
  className?: string;
  value: string | Blob | null;
  placeholder: string;
  additionalPlaceholder?: string;
  placeholderClassName?: string;
  borderColor: 'blue' | 'white';
  backgroundColor: string;
  textAlign?: 'center' | 'right';
  renderSponsorLogo?: () => React.ReactNode;
}

export const ContentArea = (props: Props) => {
  const {
    value,
    className = '',
    textAlign = 'center',
    placeholder,
    renderSponsorLogo,
    additionalPlaceholder,
    placeholderClassName = '',
  } = props;
  const isFileArea = typeof value !== 'string';
  const [areaBG, setAreaBG] = React.useState('');
  const notEmptyValue = Boolean(value);
  const contentStyles = React.useMemo(() => {
    if (notEmptyValue) {
      return { borderColor: 'none', border: 'none', background: 'none' };
    }
    return { borderColor: props.borderColor === 'blue' ? '#034092' : 'white', background: props.backgroundColor };
  }, [notEmptyValue]);

  React.useEffect(() => {
    if (isFileArea && value) {
      const reader = new FileReader();

      reader.onloadend = () => setAreaBG(reader.result as string);
      reader.readAsDataURL(value);
    }
  }, [value]);

  const renderContent = () => {
    if (isFileArea && value) {
      return null;
    }

    if (!isFileArea && value) {
      return value;
    }

    return (
      <div className={cn(css.placeholder, placeholderClassName)}>
        <p>{placeholder}</p>
        {additionalPlaceholder && <p>{additionalPlaceholder}</p>}
      </div>
    );
  };

  return (
    <div
      className={cn(css.contentArea, notEmptyValue && css.withValue, className)}
      style={areaBG ? { backgroundImage: `url('${areaBG}')` } : undefined}
    >
      <div className={cn(css.content, textAlign === 'right' && css.rightAlign)} style={contentStyles}>
        {renderContent()}
      </div>

      {renderSponsorLogo?.()}
    </div>
  );
};
